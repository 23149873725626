
$content-width: 610px; // 38em in main font
$wide-content-width: 700px; // 48em in the main font

$tp-navbar-height: 40px;

// Page container
.tp-all {
  width: 100%;
  @include make-container();

  padding-right: 0;
  padding-left: 0;
}

// Page row, contains columns:
// - tp-content-container (main page content)
//   -tp-content
//   -tp-footer
// - tp-menu (menu bar at left)     \
// - tp-navbar (menu bar at top)    /\
//                                    mutually exclusive
.tp-all-inner {
  @include make-row();

  @include media-breakpoint-down(sm) {
    //padding-top: $tp-navbar-height;
  }
}

// Content column (set a maximum page width; align it to left)
.tp-content-container {
  @include make-col-ready();

  @include media-breakpoint-up(md) {
    @include make-col-offset(2, 10);
    padding-top: .5em !important;
    padding-right: 1em !important;
    padding-left: 0 !important;
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }

  order: 2;
  // max-width: 60em;
}

// Content
.tp-content {
  // Wrapper for page content to push down footer
  @include media-breakpoint-up(md) {
    height: auto;
    min-height: 100%;
    padding: 0 0 40px;    // Pad bottom by footer height
    margin: 0 1em -40px;   // Negative indent footer by its height
  }

  @include media-breakpoint-down(sm) {
    margin-right: 1.25em;
    margin-left: 1.25em;
  }

  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
li,
pre,
table {
  text-align: left;
}

@media print {
  .tp-menu {
    display: none;
  }

  .tp-content {
    // margin-left: -8em; // for safari, breaks under chrome
  }
}

// menu bar

.tp-menu {
  @include make-col-ready();

  // .fixed-top
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;

  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: 0 !important;
  background-color: theme-color("primary");

  @include media-breakpoint-up(md) {
    @include make-col(2);
    //position: fixed !important;
    order: 1;
    height: 100%;
    padding-top: .5em !important;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }

  ul {
    padding: 0;
    padding-left: 1em;
    margin: 0;
    list-style: none;

    li {
      overflow: hidden !important;
      color: #fff;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .nolink {
    font-style: italic;
  }

  .active {
    font-weight: bold;
  }

  a:link,
  a:visited,
  a:active {
    color: #fff;
    text-decoration: none;
  }

  a:hover {
    color: theme-color("danger");
    text-decoration: none;
  }
}

.tp-innermenu h2 {
  display: none;
}


// Page information in footer

.tp-footer {
  @include make-container();

  margin-top: 1em;
  text-align: left;

  // Set the fixed height of the footer here
  @include media-breakpoint-up(md) {
    height: 40px;
  }

  .tp-footer-author,
  .tp-footer-date {
    font-size: x-small;
  }

  .tp-footer-author {
    font-style: italic;
  }

  .tp-footer-date {
    color: #c0c0c0;
    text-align: right !important;
  }
}

.tp-footercontent {
  @include make-row();

  padding-top: .2em;
  border-top: 1px #000 solid;

  @include media-breakpoint-up(md) {
    margin-right: 1em;
  }

  @include media-breakpoint-down(sm) {
  }
}

.tp-footer-author {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col(5);
  }
}

.tp-footer-date {
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col(7);
  }
}


// Sticky footer
html,
body,
.tp-all,
.tp-all-inner,
.tp-content-container {
  @include media-breakpoint-up(md) {
    height: 100%;
  }
}


// My site CSS

p {
  text-align: justify;
}

p,
ul,
ol,
dl,
dt,
dd,
.table,
.table td,
.keywords,
.publisher,
form,
img,
.authors {
  max-width: $content-width;
}

.lessimportant {
  font-size: smaller;
}

form {
  margin-bottom: 16px;
}

.author {
  display: inline;
  white-space: nowrap;
}

address {
  text-align: left;
}

.wider {
  max-width: $wide-content-width;
}

.fullwidth {
  max-width: none !important;
}

.in-list,
.in-list {
  padding-top: .5em;
  padding-left: 1.5em;
  margin-top: 0;
}

.mainitems {
  padding-left: 1.5em;

  li {
    margin-bottom: 1em;
  }
}

.linklist li {
  @include media-breakpoint-down(xs) {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: larger;
  }
}

.lessgap,
.lessgap {
  margin-top: -.6em;
}

.anchorjs-link {
  margin-left: -1.1em !important;
  text-decoration: none;
  transition: all .25s linear;
}

kbd {
  display: inline-block;
  margin: 0 .1em;
  font-size: 11px !important;
  color: #333 !important;
  text-shadow: 0 1px 0 #fff;
  background-color: #f7f7f7 !important;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0,0,0,.2),0 0 0 2px #fff inset;
}

.fingerprint {
  display: block;
  font-size: x-small;
}

.md5-checksum {
  font-size: x-small;
}

.photo {
  @include media-breakpoint-down(xs) {
    padding-left: 2.5em;
  }
}

// Front page news

.news {
  .age5,
  .age5 a {
    color: $gray-300;
  }

  .age4,
  .age4 a {
    color: $gray-400;
  }

  .age3,
  .age3 a {
    color: $gray-500;
  }

  .age2,
  .age2 a {
    color: $gray-600;
  }

  .age1,
  .age1 a {
    color: $gray-800;
  }
}

// Figures

figure {
  display: block;
  max-width: $content-width;
  margin-top: 1em;
  margin-right: auto;
  margin-bottom: 1em;
  margin-left: 0;
  text-align: center;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.figure > img {
  padding-bottom: .7em;
}

.img-screenshot-small {
  @include media-breakpoint-up(sm) {
    max-width: 400px;
  }

  @include media-breakpoint-down(xs) {
    @include img-fluid;
  }
}

.img-screenshot-medium {
  @include media-breakpoint-up(md) {
    max-width: 500px;
  }

  @include media-breakpoint-down(sm) {
    @include img-fluid;
  }
}

.caption {
  margin-top: .5em;
  margin-bottom: .8em;
}

.figure-main-caption {
  margin-top: -1em;
  margin-bottom: 1em;
  text-align: center;
}

figcaption {
  padding-top: .8em;
  font-size: $figure-caption-font-size;
  color: $figure-caption-color;
}

.ondropshadow {
  margin-top: -3em;
}

// Publications

.bibentries {
  padding-left: .5em;
  list-style-type: none;

  .link {
    padding-right: .3em;

    a::before {
      content: "[";
    }

    a::after {
      content: "]";
    }
  }

  li {
    padding-bottom: 1em;
  }

  div {
    display: inline;
  }

  .title {
    font-style: italic;
    font-weight: bold;
  }

  .number::before {
    content: "(";
  }

  .number::after {
    content: ")";
  }

}

.paper {
  h2 {
    padding-top: 1em;
    margin-top: 1em;
    border-top: 0;
  }

  .authors {
    font-weight: bold;
    text-align: left;

    a {
      color: #000;
      text-decoration: none;
    }

    a:hover {
      color: theme-color("danger");
    }
  }

  .and {
    font-weight: normal;
  }

  .comma {
    font-weight: normal;
  }

  .affiliation {
    font-size: smaller;
    font-weight: normal;
  }

  .date {
    padding-top: 1em;
    font-weight: bold;
    text-align: left;
  }

  .keywords {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: left;

    .keywords {
      font-style: italic;
      font-weight: normal;
    }

    + .publisher {
      margin-top: -1em;
    }

    span {
      font-style: normal;
      font-weight: bold;
    }
  }

  .publisher {
    margin-bottom: 1em;
    text-align: left;

    .publisher-label {
      font-style: normal;
      font-weight: bold;
    }

    .publisher {
      font-style: italic;
      font-weight: normal;
    }
  }
}

.notelist {
  ul {
    padding-left: 1.2em;
  }

  li {
    padding-bottom: 1ex;
  }
}

.acmdlitem {
  font-family: sans-serif;
  font-size: smaller;
}

.acmdlstat {
  font-size: smaller;
  font-weight: bold;
}

// CSV files
.csvfile {
  font-family: sans-serif;
  font-size: smaller;
  text-align: left;
  background-color: #000;

  td {
    padding-right: 3em;
    padding-left: .5em;
    background-color: #fff;
    border-width: 0;
  }
}

// Code
.fixgap::first-line {
  line-height: 0;
}

pre {
  max-width: $wide-content-width;
  word-break: break-all;
  white-space: pre-wrap;
}

// Manual pages
.manpage {
  h2 {
    font-size: 16pt;
  }

  pre {
    padding: 0;
    font-family: monospace;
    font-size: normal;
    background-color: #fff;
  }

  .bold {
    font-weight: bold;
  }

  .underline {
    font-style: italic;
  }
}

// Footnotes

.footnotes {
  ol {
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: smaller;
    text-align: left;
  }

  a {
    text-decoration: underline;
  }
}

// Navbar

.tp-navbar {
  //background-color: rgba(theme-color("primary"), .7) !important;
  order: 1;
  width: 100%;
  max-height: $tp-navbar-height;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.tp-nav {
  min-width: 900px; // stop overflow
}

// ensure that anchors align below the navbar
*[id] {
  @include media-breakpoint-down(sm) {
    padding-top: $tp-navbar-height;
    margin-top: -$tp-navbar-height;
  }
}

.nav-item {
  padding-right: .1em !important;
  padding-left: .1em !important;
  color: #fff !important;
}

.nav-link {
  padding: 0 !important;
  color: #fff !important;
}

.tp-navbar-home {
  font-weight: bold !important;
}

